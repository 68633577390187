import { FETCH_TRANSACTION, FETCH_GATEWAY, CANCEL_TRANSACTION, SEND_PAYIN_PROOF } from "./actionTypes";
import { FETCH_TRANSACTION_URL, FETCH_GATEWAY_URL, SEND_PAYIN_PROOF_URL, CANCEL_TRANSACTION_URL } from "../../helper/url_helper";
import api from "../../helper/api_helper";
// import { toast } from "react-toastify";
import toast from 'react-simple-toasts';

export const fetchTransaction = (data) => {
  return async (dispatch) => {
    try {
      let res = await api.post(FETCH_TRANSACTION_URL, data);
      if (res.data.status === "SUCCESS") {
        dispatch({ type: FETCH_TRANSACTION, payload: res.data.data });
      } else {
        toast(res.data.message);
      }
    } catch (error) {
      toast(error.message);
    }
  };
};

export const fetchGateway = (data) => {
  return async (dispatch) => {
    try {
      let res = await api.post(FETCH_GATEWAY_URL, data);
      if (res.data.status === "SUCCESS") {
        dispatch({ type: FETCH_GATEWAY, payload: res.data.data });
      } else {
        toast(res.data.message);
      }
    } catch (error) {
      toast(error.message);
    }
  };
};

export const sendPayinProof = (data) => {
  return async (dispatch) => {
    try {
      let res = await api.post(SEND_PAYIN_PROOF_URL, data);
      if (res.data.status === "SUCCESS") {
        dispatch({ type: SEND_PAYIN_PROOF, payload: res.data.data });
      } else {
        toast(res.data.message);
        setTimeout(() => {
            window.location.reload();
        }, 3000);
      }
    } catch (error) {
      toast(error.message);
      setTimeout(() => {
        window.location.reload();
    }, 3000);
    }
  };
};

// export const fetchOwner = (data) => {
//   return async (dispatch) => {
//     try {
//       api.defaults.headers.common["Authorization"] = `Bearer ${data.jwttoken}`;
//       let res = await api.get(FETCH_OWNER_URL);
//       if (res.data.status === "SUCCESS") {
//         dispatch({ type: FETCH_OWNER, payload: res.data.payload });
//       } else {
//         toast.error(res.data.message);
//       }
//     } catch (error) {
//       toast.error(error.message);
//     }
//   };
// }