import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-simple-toasts/dist/theme/dark.css';
import Landing from './pages/Landing.jsx';
import Upi from './pages/Upi.jsx';
// import Vpa from './pages/Vpa.jsx';
import Bank from './pages/Bank.jsx';
import Success from './pages/Success.jsx';
import Cancel from './pages/Cancel.jsx';
import Loader from './pages/Loader.jsx';
import { useSelector, useDispatch } from "react-redux";
import { fetchTransaction, fetchGateway } from './store/transaction/action.jsx';
import { toastConfig } from 'react-simple-toasts';
toastConfig({ theme: 'dark' });

// Custom hook to get query parameters
const useQuery = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

function MainApp() {
  const navigate = useNavigate(); // Use the useNavigate hook
  const dispatch = useDispatch();
  const transaction = useSelector((state) => state.transaction);
  const [payinOptions, setPayinOptions] = useState({});
  const query = useQuery()
  // if not transaction then show loader
  // fetch transaction first time
  useEffect(() => {
    const data = {
      "transaction_id": query.get('tr')
    }
    dispatch(fetchTransaction(data))
  }, []);

  //set fetching transaction every 5 seconds for status chaking
  useEffect(() => {
    const timer = setInterval(() => {
      const data = {
        "transaction_id": query.get('tr')
      }
      dispatch(fetchTransaction(data));
    }, 2500);
    return () => clearInterval(timer);
  }, []);

  // fetch gateway first time
  useEffect(() => {
    if (!transaction.transaction) {
      return;
    }
    // console.log(transaction)
    if (transaction.transaction.status === "initiated" && (!transaction.gateway)) {
      const data = {
        "transaction_id": transaction.transaction.id
      }
      dispatch(fetchGateway(data))
    }
    //send to status page if not initiated
    if (["setteled", "completed"].includes(transaction.transaction.status)) {
      // navigate('/success'); // Change the route to /status
      navigate(`/success?tr=${query.get('tr')}`); // Change the route to /status
    } else if (["canceled", "rejected", "expired"].includes(transaction.transaction.status)) {
      navigate(`/cancel?tr=${query.get('tr')}`); // Change the route to /status
    }
  }, [transaction.transaction]);

  return (
    <div className="App">
      {/* <Header /> */}

      <main>
        {((!transaction.transaction.status) || (!transaction.gateway && transaction.transaction.status === "initiated")) ? <Loader /> : ""}
        {transaction.transaction.status && <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/loader" element={<Loader />} />
          {transaction.payinOptions.hasOwnProperty("UPI") && <Route path="/upi" element={<Upi />} />}
          {/* {transaction.payinOptions.hasOwnProperty("UPI") && <Route path="/vpa" element={<Vpa />} />} */}
          {transaction.payinOptions.hasOwnProperty("BANK") && <Route path="/bank" element={<Bank />} />}
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
        </Routes>}
      </main>
      {/* <Footer /> */}
    </div>
  );
}

function App() {
  return (<>
    <Router>
      <MainApp />
    </Router>
  </>);
}
export default App;