import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { sendPayinProof } from '../store/transaction/action';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Cancel = () => {
    const transaction = useSelector((state) => state.transaction);

    return (
        <>
            <section className="mb-4 bank-wrapper">
                <div className="screen-wrapper">
                    <div className="logo-wrapper">
                        <img src="/images/logo.png" alt="logo" />
                    </div>
                </div>

                <div className="screen-wrapper something-wrong-wrapper"></div>
                <div className="important-note-wrapper something-wrong-box-main">
                    <div className="something-wrong-box text-center">
                        <img src="/images/wrong-icon.png" alt="wrong-icon" />
                        <h5 className="wrong-text">{transaction.transaction.status.toUpperCase()}</h5>
                        <p className="wrong-dec">Please try generating a new transaction on the merchant site and attempt again.</p>
                        <span className="order-id">
                            Transaction ID
                        </span>
                        <h5 className="transection-id">
                            {transaction.transaction.id}
                        </h5>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Cancel