import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { sendPayinProof } from '../store/transaction/action';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ColorRing } from 'react-loader-spinner';
import toast from 'react-simple-toasts';
import {isUTR} from '../helper/common_helper'

const Bank = () => {
    const dispatch = useDispatch()
    const transaction = useSelector((state) => state.transaction);
    const [utr, setUtr] = useState("");
    const [loading, setLoading] = useState({ utr: false });

    // useEffect(()=>{
    //     if (transaction.loading === "stop"){
    //         setLoading((preState) => ({ ...preState, "utr": false }));
    //     }
    // },[transaction.loading]);

    function handleSubmit() {
        if (!utr) {
            return;
        }
        if(!isUTR(utr)){
            toast("Please enter valid 12 digit UTR number")
            return
        }
        setLoading((preState) => ({ ...preState, "utr": true }));
        const data = {
            "transaction_id": transaction.transaction.id,
            "payment_proof": utr
        }
        dispatch(sendPayinProof(data));
    }
    return (
        <>
            <section className="mb-4 bank-wrapper">
                {/* <div className="screen-wrapper">
                    <div className="logo-wrapper">
                        <img src="/images/logo.png" alt="logo" />
                    </div>
                </div> */}
                <div className="payment-back-block d-flex gap-2 py-3 align-items-center">
                    {/* <img src="images/back-arrow.png" alt="back-arrow" /> */}
                    <span className="payment-text">
                        {transaction.gateway.company_name}
                    </span>
                </div>
                <div className="screen-wrapper bank-amont-dtl">
                    <div className="amount-wrapper">
                        <span className="amout-title d-block">
                            Amount
                        </span>
                        <div className="amount-price-wrapper d-flex align-items-center justify-content-between">
                            <h4 className="price mb-0">&#8377; {transaction.transaction.amount}</h4>
                            <CopyToClipboard
                                text={transaction.transaction.amount.toString()}
                                onCopy={() => { toast('amount Copied!')}}
                            >
                                <img src="/images/copy-icon.png" alt="copy-icon" />
                            </CopyToClipboard>
                        </div>
                    </div>

                </div>
                <div className="important-note-wrapper bank-detail-wrapper">
                    <div className="bank-des-wrap">
                        <p className="bank-dec">Please transfer same amount on this account, IMPS and RTGS only</p>
                        <div className="bank-detail-group">
                            <span>Receiver Name</span>
                            <div className='d-flex justify-content-between align-items-center'>
                            <h5>{transaction.payinOptions.BANK.HOLDER_NAME}</h5>
                            <CopyToClipboard
                                text={transaction.payinOptions.BANK.HOLDER_NAME}
                                onCopy={() => { toast('Holder Copied!')}}
                            >
                            <img src="images/copy-icon.png" width="35px" alt="copy-icon" />
                            </CopyToClipboard>
                            </div>
                        </div>
                        <div className="bank-detail-group">
                            <span>Bank Name</span>
                            <div className='d-flex justify-content-between align-items-center'>
                            <h5>{transaction.payinOptions.BANK.NAME}</h5>
                            <CopyToClipboard
                                text={transaction.payinOptions.BANK.NAME}
                                onCopy={() => { toast('Bank Copied!')}}
                            >
                            <img src="images/copy-icon.png" width="35px" alt="copy-icon" />
                            </CopyToClipboard>    
                            </div>
                        </div>
                        <div className="bank-detail-group">
                            <span>Account No</span>
                            <div className='d-flex justify-content-between align-items-center'>
                            <h5>{transaction.payinOptions.BANK.ACCOUNT_NO} </h5>
                            <CopyToClipboard
                                text={transaction.payinOptions.BANK.ACCOUNT_NO}
                                onCopy={() => { toast('Account no Copied!')}}
                            >
                            <img src="images/copy-icon.png" width="35px" alt="copy-icon" />
                            </CopyToClipboard> 
                        </div>
                        </div>
                        <div className="bank-detail-group">
                            <span>IFSC</span>
                            <div className='d-flex justify-content-between align-items-center'>
                            <h5>{transaction.payinOptions.BANK.IFSC_CODE}</h5>
                            <CopyToClipboard
                                text={transaction.payinOptions.BANK.IFSC_CODE}
                                onCopy={() => { toast('IFSC code Copied!')}}
                            >
                            <img src="images/copy-icon.png" width="35px" alt="copy-icon" />
                            </CopyToClipboard> 
                            </div>
                        </div>
                    </div>
                    <div className="transaction-id-wrapper">
                        <h5>Enter UTR/Transaction ID/Ref ID</h5>
                        <input type="text" value={utr} onChange={(e) => { setUtr(e.target.value) }} placeholder='Enter 12 digit UTR number' />
                           <div className="cancle-payment-btn-block process-button-block">
                            {!loading.utr && <button onClick={handleSubmit}>Confirm Payment</button>}
                            {loading.utr && <div className='d-flex justify-content-center'><ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#1C50D0', '#1C50D0', '#1C50D0', '#1C50D0', '#1C50D0']}
                            /></div>}                        
                        </div>
                    </div>
                    <div className="transaction-important-note">
                        <h5 className="transaction-important-note-title">Important Note</h5>
                        <div className="bg-white important-note-box mt-4">
                            <div className="note-box d-flex align-items-center gap-3 mb-3">
                                <img src="images/qr-scane-icon.png" alt="qr-scane-icon" />
                                <h5>Initiate a Bank Transfer</h5>
                            </div>
                            <div className="note-box d-flex align-items-center gap-3 mb-3">
                                <img src="images/wallet-icon.png" alt="wallet-icon" />
                                <h5>Transfer to the provided account details using RTGS, or IMPS</h5>
                            </div>
                            <div className="note-box d-flex align-items-center gap-3">
                                <img src="images/gift-icon.png" alt="gift-icon" />
                                <h5>Enter the 12-digit UTR/Transaction Reference Number</h5>
                            </div>
                        </div>
                        <div className="secure-payment-wrapper">
                            <h5>
                                100% Secure Payments
                            </h5>
                            <div className="secure-payment-logo-block d-flex align-items-center gap-2">
                                <img src="images/nortone-secured-logo.png" alt="nortone-secured-logo" />
                                <img src="images/visa-logo.png" alt="visa-logo" />
                                <img src="images/mastercard-secured.png" alt="mastercard-secured" />
                                <img src="images/pci.png" alt="pci.png" />
                                <img src="images/safekey.png" alt="safekey" />
                            </div>
                        </div>
                        {/* <div className="cancle-payment-btn-block"> */}
                            {/* <button>cancle payment</button> */}
                        {/* </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Bank

