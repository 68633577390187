import { FETCH_TRANSACTION, FETCH_GATEWAY } from "./actionTypes";
let initialState = {
  transaction: {},
  gateway: false,
  payinOptions: {},
  loading:"blank"
};
const transaction = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTION:
      return {
        ...state,
        transaction: action.payload
      };
    case FETCH_GATEWAY:
      // let payinOptions = {}
      // action.payload.payinOptions.forEach((option) => {
      //   let payinOptionFields = {}
      //   option.gateway_payin_option_fields.forEach((field) => {
      //     payinOptionFields[field.name] = field.value;
      //   })
      //   payinOptions[option.symbol] = payinOptionFields
      // })
      // console.log(payinOptions)
      return {
        ...state,
        gateway: action.payload.gateway,
        payinOptions: action.payload.payin_options
      };
    default:
      return state;
  }
};
export default transaction;
