import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import QRCode from 'qrcode.react';
import { ColorRing } from 'react-loader-spinner';
import { sendPayinProof } from '../store/transaction/action';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-simple-toasts';
import {isUTR} from '../helper/common_helper'

const Upi = () => {
    const dispatch = useDispatch()
    const transaction = useSelector((state) => state.transaction);
    const [utr, setUtr] = useState("");
    const [loading, setLoading] = useState({ utr: false });

    // useEffect(()=>{
    //     if (transaction.loading === "stop"){
    //         setLoading((preState) => ({ ...preState, "utr": false }));
    //     }
    // },[transaction.loading]);

    function handleSubmit() {
        if (!utr) {
            return;
        }
        if(!isUTR(utr)){
            toast("Please enter valid 12 digit UTR number")
            return
        }
        setLoading((preState) => ({ ...preState, "utr": true }));
        const data = {
            "transaction_id": transaction.transaction.id,
            "payment_proof": utr
        }
        dispatch(sendPayinProof(data));
    }

    const [timeLeft, setTimeLeft] = useState(900); // Set initial time in seconds (e.g., 600 seconds = 10 minutes)

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => prevTime > 0 ? prevTime - 1 : 0);
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return (
        <>
            <section className="mb-4">
                {/* <div className="screen-wrapper">
                    <div className="logo-wrapper">
                        <img src="/images/logo.png" alt="logo" />
                    </div>
                </div> */}
                <div className="payment-back-block d-flex gap-2 py-3 align-items-center">
                    {/* <img src="/images/back-arrow.png" alt="back-arrow" /> */}
                    <span className="payment-text">
                        {transaction.gateway.company_name}
                    </span>
                </div>
                <div className="screen-wrapper">
                    <div className="amount-wrapper">
                        <span className="amout-title d-block">
                            Amount
                        </span>
                        <div className="amount-price-wrapper upi-click d-flex align-items-center justify-content-between">
                            <h4 className="price mb-0">&#8377; {transaction.transaction.amount}</h4>
                            <CopyToClipboard
                                text={transaction.transaction.amount.toString()}
                                onCopy={() => { toast('amount Copied!')}}
                            >
                                <img src="/images/copy-icon.png" alt="copy-icon" />
                            </CopyToClipboard>
                        </div>
                    </div>

                </div>
                <div className="important-note-wrapper">
                    <div className="proccesing-time-block text-center">
                        <h5>Processing time end in</h5>
                        <span><img src="/images/process-timer-icon.png" className="me-1" alt="process-timer-icon" />{formatTime(timeLeft)}</span>

                    </div>
                    {transaction.payinOptions.UPI.seamless_only === "no" && <div className="after-payment-block">
                        <h5>After payment, Enter 12 digits UTR and click Submit to avoid delays</h5>
                        <div className="after-payment-form d-flex align-items-center gap-4">
                            <input type="text" value={utr} onChange={(e) => { setUtr(e.target.value) }} placeholder='Enter 12 digit UTR number' />
                            {!loading.utr && <button className="payment-btn" onClick={handleSubmit}>Submit</button>}
                            {loading.utr && <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#1C50D0', '#1C50D0', '#1C50D0', '#1C50D0', '#1C50D0']}
                            />}
                        </div>
                    </div>}
                    <div className="scan-qr-wrapper text-center">
                        <h5>Scan QR Code to Pay</h5>
                        {/* <img src="/images/qr-scan.png" alt="qr-scan" /> */}
                        <QRCode value={`upi://pay?pa=${transaction.payinOptions.UPI.UPI_ID}&pn=UPI&mc=&tid=&am=${transaction.transaction.amount}&cu=INR&tn=${transaction.transaction.ref_id}`}
                            size={256}
                            fgColor="#1C50D0"
                            level="H" // Error correction level ('L', 'M', 'Q', 'H')
                        // includeMargin={true} // Include margin around the QR code
                        />
                         {/* <QRCode value={`upi://pay?pa=${transaction.payinOptions.UPI.UPI_ID}&pn=UPI Payment&tid=${transaction.transaction.ref_id}&pn=UPI Payment&mam=${transaction.transaction.amount}&am=${transaction.transaction.amount}&tr=${transaction.transaction.ref_id}&tn=${transaction.transaction.ref_id}&cu=INR&mtid=${transaction.transaction.ref_id}&mid=${transaction.transaction.ref_id}&msid=${transaction.transaction.ref_id}&url=&mode=02&purpose=00&featuretype=money_transfer`}
                            size={256}
                            fgColor="#1C50D0"
                            level="H" // Error correction level ('L', 'M', 'Q', 'H')
                        // includeMargin={true} // Include margin around the QR code
                        /> */}
                    </div>
                </div>
                <div className="screen-wrapper qr-scan-two-wrapper">
                    <div className="scan-2-wrapper d-flex align-items-center justify-content-between">
                        <h5>{transaction.payinOptions.UPI.UPI_ID}</h5>
                        <CopyToClipboard
                            text={transaction.payinOptions.UPI.UPI_ID}
                            onCopy={() => { toast('UPI Copied!')}}
                            >
                            <img src="/images/copy-icon.png" alt="copy-icon" />
                        </CopyToClipboard>
                    </div>
                    <div className="important-two-wrapper">
                        <p className="mt-4">This is only valid one-time payment. Return to the merchant’s site for another transaction.</p>
                        <h1 className="important-note">Important Note</h1>
                    </div>

                </div>
                <div className="important-note-wrapper important-note-wrapper-two">
                    <div className="important-note-box">
                        <div className="note-box d-flex align-items-center gap-3 mb-3">
                            <img src="/images/qr-scane-icon.png" alt="qr-scane-icon" />
                            <h5>Scan QR Code</h5>
                        </div>
                        <div className="note-box d-flex align-items-center gap-3 mb-3">
                            <img src="/images/wallet-icon.png" alt="wallet-icon" />
                            <h5>Pay using any UPI Apps Like: Paytm, Phone Pay, BHIM, etc.</h5>
                        </div>
                        <div className="note-box d-flex align-items-center gap-3">
                            <img src="/images/gift-icon.png" alt="gift-icon" />
                            <h5>{transaction.payinOptions.UPI.seamless_only === "no" ? "Insert 12 digits UTR/UPI Reference Number" : "Wait few seconds until success"}</h5>
                        </div>
                    </div>
                    <div className="secure-payment-wrapper">
                        <h5>
                            100% Secure Payments
                        </h5>
                        <div className="secure-payment-logo-block d-flex align-items-center gap-2">
                            <img src="/images/nortone-secured-logo.png" alt="nortone-secured-logo" />
                            <img src="/images/visa-logo.png" alt="visa-logo" />
                            <img src="/images/mastercard-secured.png" alt="mastercard-secured" />
                            <img src="/images/pci.png" alt="pci.png" />
                            <img src="/images/safekey.png" alt="safekey" />
                        </div>
                        {/* <div className="cancle-payment-btn-block">
                            <button >cancle payment</button>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Upi