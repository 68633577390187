import React from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

const Landing = () => {
    const navigate = useNavigate();
    const transaction = useSelector((state) => state.transaction);
    function changeRoute(route){
        navigate(`${route}?tr=${transaction.transaction.id}`);
    }
    return (
        <>
            <section className="mb-4">
                {/* <div className="screen-wrapper">
                    <div className="logo-wrapper">
                        <img src="/images/logo.png" alt="logo" />
                    </div>
                </div> */}
                <div className="payment-back-block d-flex gap-2 align-items-center py-3">
                    {/* <img src="images/back-arrow.png" alt="back-arrow" /> */}
                    <span className="payment-text">
                        {transaction.gateway.company_name}
                    </span>
                </div>
                <div className="screen-wrapper">
                    <div className="amount-wrapper">
                        <span className="amout-title d-block">
                            Amount
                        </span>
                        <div className="amount-price-wrapper d-flex align-items-center justify-content-between">
                            <h4 className="price mb-0">&#8377; {transaction.transaction.amount}</h4>
                            <img src="images/copy-icon.png" alt="copy-icon" />
                        </div>
                    </div>
                    <div className="payment-method-wrapper">
                    {/* {transaction.payinOptions.hasOwnProperty("UPI") && <div className="payment-method-block payment-upi d-flex align-items-center justify-content-between mb-3" onClick={()=>{changeRoute("/vpa")}}>
                            <div className="payment-method d-flex align-items-center gap-4">
                                <img src="images/upi-icon.png" alt="upi-icon" />
                                <div className="payment-name">
                                    <h5>UPI</h5>
                                    <span>Request on your VPA</span>
                                </div>
                            </div>
                            <div className="forword-arrow">
                                <img src="images/forword-arrow.png" alt="forword-arrow" />
                            </div>
                        </div>} */}
                        {transaction.payinOptions.hasOwnProperty("UPI") && <div className="payment-method-block payment-upi d-flex align-items-center justify-content-between mb-3" onClick={()=>{changeRoute("/upi")}}>
                            <div className="payment-method d-flex align-items-center gap-4">
                                <img src="images/upi-icon.png" alt="upi-icon" />
                                <div className="payment-name">
                                    <h5>UPI</h5>
                                    <span>Copy UPI or Scan Qr</span>
                                </div>
                            </div>
                            <div className="forword-arrow">
                                <img src="images/forword-arrow.png" alt="forword-arrow" />
                            </div>
                        </div>}
                        {transaction.payinOptions.hasOwnProperty("BANK") && <div className="payment-method-block d-flex align-items-center justify-content-between" onClick={()=>{changeRoute("/bank")}}>
                            <div className="payment-method d-flex align-items-center gap-4">
                                <img src="images/bank-transfer-icon.png" alt="bank-transfer-icon" />
                                <div className="payment-name">
                                    <h5>Bank Transfer</h5>
                                    <span>IMPS and RTGS only</span>
                                </div>
                            </div>
                            <div className="forword-arrow">
                                <img src="images/forword-arrow.png" alt="forword-arrow" />
                            </div>
                        </div>}

                        
                        {/* {showProcessBankBtn && (
                                <div className="cancle-payment-btn-block process-button-block">
                                    <button onClick={handleProceedWithBankClick}>Process with Bank</button>
                                </div>
                            )} */}

                    </div>
                </div>
                <div className="important-note-wrapper">
                    <div className="important-note-block">
                        <h5>Important Note</h5>
                    </div>
                    <div className="bg-white important-note-box">
                        <div className="note-box d-flex align-items-center gap-3 mb-3">
                            <img src="images/qr-scane-icon.png" alt="qr-scane-icon" />
                            <h5>Choose a payment method</h5>
                        </div>
                        <div className="note-box d-flex align-items-center gap-3 mb-3">
                            <img src="images/wallet-icon.png" alt="wallet-icon" />
                            <h5>Pay the specified amount using the chosen method.</h5>
                        </div>
                        <div className="note-box d-flex align-items-center gap-3">
                            <img src="images/gift-icon.png" alt="gift-icon" />
                            <h5>Wait until the payment is confirmed successfully.</h5>
                        </div>
                    </div>
                    <div className="secure-payment-wrapper">
                        <h5>
                            100% Secure Payments
                        </h5>
                        <div className="secure-payment-logo-block d-flex align-items-center gap-2">
                            <img src="images/nortone-secured-logo.png" alt="nortone-secured-logo" />
                            <img src="images/visa-logo.png" alt="visa-logo" />
                            <img src="images/mastercard-secured.png" alt="mastercard-secured" />
                            <img src="images/pci.png" alt="pci.png" />
                            <img src="images/safekey.png" alt="safekey" />
                        </div>
                    </div>
                    {/* <div className="cancle-payment-btn-block">
                        <button >cancle payment</button>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default Landing